<template>
    <v-footer class="py-5 grey lighten-3">
        
        <div class=" text-center pa-5" style="width: 100%;">
            
            <h4 class="mb-2">資料來源</h4>
            <p>
                郭蕓甄、吳相儀（2023）。「新編青少年強項量表」簡版之發展。教育與心理研究，46（2），31-68。
            </p>
        </div>
    </v-footer>
</template>

<script>
export default {
    name: "AppFooter",
    components: {},
};
</script>

<style scoped>

</style>