import servicesUtils from '../utils/service'

export const schoolServices = {
    async getGrade(d) {
        try {
            const url = `${servicesUtils.resourceUrl('schoolGrade')}`
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getGrade: ${JSON.stringify(d)}, err: ${err}`)
            throw err
        }
    },
    async getSchool(d) {
        try {
            const url = `${servicesUtils.resourceUrl('school')}` + '?' + servicesUtils.queryParams(d)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get getSchool: ${JSON.stringify(d)}, err: ${err}`)
            throw err
        }
    },

    async getSchoolClass(d) {
        try {
            const url = `${servicesUtils.resourceUrl('schoolClasses')}` + '?' + servicesUtils.queryParams(d)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get schoolClasses: ${JSON.stringify(d)}, err: ${err}`)
            throw err
        }
    },
}