<template>
    <div>
        <!-- <div>
            <v-btn
                class="mr-4"
                min-width="0"
                outlined
                text
                small
                @click="$router.go(-1)"
                title="回上一頁"
                value="回上一頁"
                aria-label="回上一頁"
            >
                <v-icon>mdi-arrow-left</v-icon>回上一頁
            </v-btn>
        </div> -->
        <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">評量說明</h2>
        </div>
        <div class="main-content row no-gutters justify-center mt-4" style="max-width: 850px;">
            <v-col cols="12" class="mb-3">
                <div class="d-block d-md-flex flex-no-wrap">
                    <div class="text-center">
                        <v-avatar class="ma-3" size="200" tile>
                            <v-img :src="thumbnail" :alt="title"></v-img>
                        </v-avatar>
                    </div>
                    <div>
                        <v-card-title class="text-h5" v-text="title"></v-card-title>

                        <v-sheet class="px-4" v-html="quizHint"></v-sheet>

                        <v-card-actions class="mt-2">
                        <v-btn class="ml-2 white--text" width="100%" elevation="0" color="red accent-1" title="開始測驗" value="開始測驗" aria-label="開始測驗" @click="goToPersonalData()">
                            開始測驗
                        </v-btn>
                        </v-card-actions>
                    </div>

                </div>
            </v-col>
        </div>
    </div>
</template>

<script>
import { quizServices } from "@/services/quiz.js";

export default {
    name: "QuizInstructions",
    components: {},
    data() {
        return {
            quizId: -1,
            title: '',
            quizHint: '',
            thumbnail: '',
        };
    },
    async created() {

        this.$store.commit("setQuizTitle", '');
       // this.$root.$emit('scaleTitle', '');
        sessionStorage.removeItem('quiz_id');
        sessionStorage.removeItem('user_id');
        sessionStorage.removeItem('user_sub');

        let id = 1;//this.$route.params.id;
        const quiz = await quizServices.getQuizInstructions(id);
        //console.log(quiz.data.id)
        if (!quiz.data.id) {
            this.$router.push("/scale")
        }

        this.quizId = quiz.data.id;
        this.quizHint = quiz.data.hint;
        this.title = quiz.data.title;
        this.thumbnail = quiz.data.thumbnailUrl;
        
        sessionStorage.quiz_id = this.quizId;
        sessionStorage.scale_title = this.title;
    },
    methods: {
        goToPersonalData() {
            sessionStorage.setItem('redirect_path', `/scale/${this.quizId}/personalData`);
            this.$router.push('/login/check');
            //this.$router.push(`/scale/${this.quizId}/personalData`);
        },
    },
};
</script>

<style scoped>

</style>