<template>
    <v-app>
        <AppHeader />
        <div class="main-area" :class="mainDivClass">
        <v-container>
            <router-view :key="$route.path"></router-view>
        </v-container>
        </div>
        <AppFooter />
    </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import AppFooter from "@/components/AppFooter.vue";

export default {
    name: "AppPortal",

    components: {
        AppHeader,
        AppFooter,
    },

    data: () => ({
        //
    }),
    computed: {
        mainDivClass: {
            get() {
                if (!this.$store.state.quizTitle) {
                    return "main-section";
                } else {
                    return "grey lighten-5";
                }
            },
        },
    },
};
</script>

<style>
@font-face { 
	font-family: BopomofoRuby;
	src: url('~@/assets/font/BpmfGenSenRounded-R.ttf');
}
.v-application, 
.v-application .text-h1,
.v-application .text-h2,
.v-application .text-h3,
.v-application .text-h4,
.v-application .text-h5,
.v-application .text-h6  {
    font-family: BopomofoRuby!important;;
}
.v-application {
    font-size: 1.2rem;
}
.v-btn.v-size--default {
    font-size: 1.1rem;
}
#app {
    max-width: 100%;
    /* margin: 0 auto;
    padding: 2rem; */

    font-weight: normal;
    /*background-color: var(--v-background-lighten5);*/ /*--v-background-base*/
}
.page-title {
    border-bottom: 2px solid #E57373;
}
.main-section {
    /* background-image: url(~@/assets/img/main_bg.png); */
    background-size: contain;
    background-position: bottom;
    padding-bottom: 50px;
}
.main-content {
    min-height: calc(100vh - 338px);
    margin-left: auto !important;
    margin-right: auto !important;
}
.main-content.error-page {
    min-height: 58vh;
    margin-left: auto !important;
    margin-right: auto !important;
}
.w-100 {
    width: 100%;
}
.main-area {
    min-height: calc(100vh - 230px);
}
/* @media (min-width: 1024px) {
    body {
        display: flex;
        place-items: center;
    }

    #app {
        display: grid;
        grid-template-columns: 1fr 1fr;
        padding: 0 2rem;
    }

    .wrapper {
        display: flex;
        place-items: flex-start;
        flex-wrap: wrap;
    }

    nav {
        text-align: left;
        margin-left: -1rem;
        font-size: 1rem;

        padding: 1rem 0;
        margin-top: 1rem;
    }
} */
</style>