<template>
    <div>
        <v-card color="grey lighten-4" flat tile>
            <v-toolbar height="80px" class="pa-0">
                <a
                    href="/"
                    :class="
                        scaleTitle == ''
                            ? 'logo-img '
                            : 'logo-img d-none d-sm-block'
                    "
                    class="main-logo mx-2"
                    title="回到首頁"
                >
                    回到首頁
                </a>

                <v-spacer></v-spacer>

                <div v-if="scaleTitle == '' || scaleTitle == null">
                    <v-toolbar-items class="d-none d-md-block">
                        <template v-for="item in menu">
                            <v-btn
                                v-if="item.children.length == 0"
                                :to="item.url"
                                :key="item.id"
                                text
                                :title="item.title"
                                :value="item.title"
                                :aria-label="item.title"
                                >{{ item.title }}</v-btn
                            >

                            <v-menu
                                v-else
                                :key="item.id"
                                offset-y
                                open-on-hover
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn
                                        v-bind="attrs"
                                        v-on="on"
                                        text
                                        :title="item.title"
                                        :value="item.title"
                                        :aria-label="item.title"
                                    >
                                        {{ item.title }}
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item
                                        v-for="subMenu in item.children"
                                        :key="subMenu.id"
                                    >
                                        <v-list-item-title>
                                            <v-btn
                                                :to="subMenu.url"
                                                :key="subMenu.id"
                                                text
                                                block
                                                :title="subMenu.title"
                                                :value="subMenu.title"
                                                :aria-label="subMenu.title"
                                                >{{ subMenu.title }}</v-btn
                                            >
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </template>
                    </v-toolbar-items>

                    <v-btn
                        icon
                        @click.stop="drawer = !drawer"
                        class="d-block d-md-none"
                        aria-label="開啟或關閉網站選單"
                        title="開啟或關閉網站選單"
                        value="開啟或關閉網站選單"
                    >
                    <v-icon>mdi-menu</v-icon>
                    </v-btn>
                </div>
                <div v-else class="scale-title">
                    <v-btn
                        class="mr-4 d-sm-none"
                        min-width="0"
                        outlined
                        text
                        icon
                        small
                        to="/scale"
                        :title="scaleTitle"
                        :value="scaleTitle"
                        :aria-label="scaleTitle"
                    >
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <span>{{ scaleTitle }}</span>
                </div>
            </v-toolbar>
        </v-card>
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
            off-icon="false"
        >
            <div class="pa-2 logo-block">
                <a
                    href="/"
                    :class="logoClass"
                    class="main-logo"
                    title="回到首頁"
                >
                    回到首頁
                </a>
            </div>
            <v-divider class="mb-1" />
            <v-list>
                <template v-for="item in menu">
                    <v-list-item
                        v-if="item.children.length == 0"
                        :key="item.id"
                    >
                        <v-list-item-title>
                            <v-btn
                                text
                                :to="item.url"
                                :title="item.title"
                                :value="item.title"
                                :aria-label="item.title"
                                >{{ item.title }}</v-btn
                            >
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-group :key="item.id" :value="false" v-else>
                        <template v-slot:activator>
                            <v-list-item-title>
                                <v-btn
                                    text
                                    :title="item.title"
                                    :value="item.title"
                                    :aria-label="item.title"
                                    >{{ item.title }}</v-btn
                                >
                            </v-list-item-title>
                        </template>

                        <v-list-item
                            v-for="subMenu in item.children"
                            :key="subMenu.id"
                        >
                            <v-list-item-title class="pl-3">
                                <v-btn
                                    text
                                    :to="subMenu.url"
                                    :title="subMenu.title"
                                    :value="subMenu.title"
                                    :aria-label="subMenu.title"
                                    >{{ subMenu.title }}</v-btn
                                >
                            </v-list-item-title>
                        </v-list-item>
                        <v-divider class="mb-1" />
                    </v-list-group>
                </template>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>
import { menuServices } from "@/services/menu.js";

export default {
    name: "AppHeader",
    components: {},
    data() {
        return {
            drawer: null,
            //scaleTitle: "",
            logoClass: "logo-img",
            menu: [],
            mainLogo: require("@/assets/img/main_logo.jpeg"),
        };
    },
    async created() {
        const data = await menuServices.getMenuList();
        let menuMap = data.data;

        let menuList = menuMap.menus;
        let subMenuMap = menuMap.sub_menu_map;
        for (let i = 0; i < menuList.length; i++) {
            let m = menuList[i];
            let menu = this.parseMenu(m);

            let subMenuList = subMenuMap[m.id];
            if (subMenuList != null) {
                for (let smInd = 0; smInd < subMenuList.length; smInd++) {
                    menu.children.push(this.parseMenu(subMenuList[smInd]));
                }
            }
            this.menu.push(menu);
        }
    },
    mounted() {
        // this.$root.$on("scaleTitle", (data) => {
        //     this.scaleTitle = data;
        //     if (data != "") {
        //         this.logoClass = "logo-img d-none d-sm-block";
        //     } else {
        //         this.logoClass = "logo-img";
        //     }
        // });
    },
    computed: {
        scaleTitle: {
            get() {
                return this.$store.state.quizTitle;
            },
        },
    },
    methods: {
        menuItems() {
            return this.menu;
        },
        parseMenu(m) {
            let menu = {
                id: m.id,
                url: m.url == null ? "/menu/" + m.id : "/" + m.url,
                title: m.name,
                description: m.description,
                children: [],
            };
            return menu;
        },
    },
};
</script>

<style scoped>
.logo-block {
    height: 65px;
}
.main-logo {
    background-image: url(~@/assets/img/main_logo.png);
    background-position: center center;
    background-size: contain;
    max-height: 65px;
    max-width: 150px;
    width: 100%;
    height: 100%;
    color: transparent;
    display: block;
}
.navbar-container {
    width: 100%;
    border-bottom-style: solid;
    border-bottom-color: #95c781;
    -webkit-box-shadow: 0px 1px 2px #bababa;
    -moz-box-shadow: 0px 1px 2px #bababa;
    box-shadow: 0px 1px 2px #bababa;
}
.drawer-menu-list-item {
    border-bottom: 2px solid #20780c;
}
.v-toolbar {
    box-shadow: 0px 5px #fcd379 !important;
}
/deep/.v-toolbar__content {
    padding: 0px !important;
}
.v-toolbar__items {
    height: 80px;
}
.v-btn--active {
    color: #f08c7f;
    background-color: #ffffff !important;
}
.scale-title {
    line-height: 80px;
    color: #fff;
    font-size: 1.2rem;
    background-color: #f08c7f;
    text-align: center;
    width: 100%;
}
.scale-title > span {
    margin-right: 30px;
}
.navbar {
    list-style: none;
    background: white;
    height: 115.8px;
}
#banner-img {
    /*border: 1px solid black;*/
    border-radius: 30px;
    width: 80px;
    height: 105.8px;
    padding: 4px;
}
#banner-img:hover {
    background: rgb(168, 168, 168);
    transition: all 0.4s ease 0s;
}
#banner-img:active {
    background-color: rgb(24, 24, 24);
    border-color: white;
    transition: 0s;
}
@media (min-width: 600px) {
    .scale-title {
        line-height: 80px;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 26% 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 26% 100%);
        color: #fff;
        font-size: 1.1rem;
        padding-left: 50px;
        background-color: #f08c7f;
        text-align: right;
        width: auto;
    }
}
</style>