import servicesUtils from '../utils/service'

export const quizServices = {
    async getQuizList() {
        try {
            const url = `${servicesUtils.resourceUrl('quizList')}`
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get quizList err: ${err}`)
            throw err
        }
    },
    async getQuizInstructions(id) {
        try {
            const url = `${servicesUtils.resourceUrl('quizInstruction')}/`.replace('${quizId}', id)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get quiz instruction: ${JSON.stringify(id)}, err: ${err}`)
            throw err
        }
    },
    async getQuizQuest(id) {
        try {
            const url = `${servicesUtils.resourceUrl('quizQuest')}/`.replace('${quizId}', id)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get quiz: ${JSON.stringify(id)}, err: ${err}`)
            throw err
        }
    },
    async saveQuizData(id, ans) {
        try {
            const url = `${servicesUtils.resourceUrl('saveQiz')}`.replace('${quizId}', id)
            const data = await servicesUtils.post(url, ans)
            return data
        } catch (err) {
            console.log(`failed to save user data: ${JSON.stringify(ans)}, err: ${err}`)
            throw err
        }
    },
    async getQuizResult(uuid) {
        try {
            const url = `${servicesUtils.resourceUrl('qizAns')}`.replace('${quizUUID}', uuid)
            const data = await servicesUtils.get(url)
            return data
        } catch (err) {
            console.log(`failed to get quiz result data: ${JSON.stringify(uuid)}, err: ${err}`)
            throw err
        }
    },
}