<template>
    <div id="loginRedirect">
        <v-overlay :value="overlay">
            <v-progress-circular indeterminate size="64" color="deep-orange lighten-2"></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import { userLoginServices } from "@/services/userLogin.js";

export default {
    name: "loginRedirect",
    components: {},
    data() {
        return {
            code: '',
            overlay: true,
        };
    },
    async created() {
        const consoleIndexUrl = this.$store.getters.consoleIndexUrl;
        if (consoleIndexUrl != null) {
            console.log("consoleIndexUrl", consoleIndexUrl);
            // this.$router.push(consoleIndexUrl);
        }
        this.code = this.$route.query.code;
        //TODO: 用code登入後端
        const data = {
            code: this.code,
        }
        const res = await userLoginServices.getUserInfo(data);
        if (res.sub) {
            sessionStorage.setItem('user_sub', res.sub);
            let toRedirectPath = sessionStorage.getItem('redirect_path');
            sessionStorage.removeItem('redirect_path');
            this.$router.push(toRedirectPath);
        }
    },
    mounted() {

    },
    methods: {

    },
};
</script>

<style scoped></style>