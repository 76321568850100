<template>
    <v-row class="vh-100 align-center align-self-center">
        <v-col>
            <v-progress-linear color="amber darken-3" indeterminate rounded height="6"></v-progress-linear>
        </v-col>
    </v-row>
</template>

<script>
import { userLoginServices } from "@/services/userLogin.js";

export default {
    name: "LoginCheck",
    async created() {
        userLoginServices.oauthLogin();
    },
};
</script>

<style scoped>
.vh-100 {
    height: 100vh;
}
</style>