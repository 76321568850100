import fetch from 'node-fetch'
import router from '@/router'

const httpHeaders = {
     'Content-Type': 'application/json; charset=UTF-8',
}

const checkStatus = (res) => {
    if (res.status >= 200 && res.status < 300) {
        return res
    } else {
        let error = new Error(res.statusText)
        let path = '/error';
        switch (res.status) {
            case 404: path = '/404'; break;
            case 401: path = '/login'; break;
        }
        router.push(path);
        throw error;
    }
}

export default {
    async get(url) {
        try {
            const res = await fetch(url, {
                method: 'get',
                headers: httpHeaders,
                credentials: 'include'
            })
            const data = checkStatus(res)
            return data.json()
        } catch (err) {
            console.log(`client failed to get ${url}, err: ${err}`)
            throw err
        }
    },

    async post(url, d) {
        try {
            return fetch(url, {
                method: 'post',
                body: JSON.stringify(d),
                headers: httpHeaders,
                credentials: 'include'
            }).then(res => {
                const data = checkStatus(res)
                return data.json()
            })

        } catch (err) {
            console.log(`client failed to post ${url}, err: ${err}`)
            throw err
        }
    },

    async postFormData(url, d) {
        try {
            const headers = Object.assign({}, httpHeaders)
            delete headers['Content-Type'];

            return fetch(url, {
                method: 'post',
                body: d,
                headers: headers,
                credentials: 'include'
            }).then(res => {
                const data = checkStatus(res)
                return data.json()
            })

        } catch (err) {
            console.log(`client failed to postFormData ${url}, err: ${err}`)
            throw err
        }
    },
    async delete(url) {
        try {
            const res = await fetch(url, {
                method: 'delete',
                headers: httpHeaders,
                credentials: 'include'
            })
            const data = checkStatus(res)
            return data.json()
        } catch (err) {
            console.log(`client failed to delete ${url}, err: ${err}`)
            throw err
        }
    },
    queryParams(params) {
        return Object.keys(params)
            .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
            .join('&');
    },

    resourceUrl(r) {
        const resource = {
          user: '/user',
          city: '/city',
          education: '/education',
          quizList: '/quiz',
          quizInstruction: '/quiz/${quizId}/instruction',
          quizQuest: '/quiz/${quizId}/quest',
          saveQiz: '/quiz/${quizId}',
          qizAns: '/quiz/analysis/${quizUUID}',
          menuList: '/menu',
          menu: '/menu/${url}',
          menuContentList: '/menu/${url}/content',
          menuContent: '/content/${contentId}',

          school: '/school',
          schoolClasses: '/school/classes',
          schoolGrade: '/school/grades',

          login: '/auth/signIn',
          logout: '/auth/logout',
          adminName: '/auth/my',
          sysFunction: '/auth/sysFunction',
          quizResultList: '/auth/quiz',
          consoleMenuList: '/auth/menu',
          consoleMenu: '/auth/menu/${id}',
          ConsoleSaveMenuLink: '/auth/menu/${id}/link',
          consoleMenuContentList: '/auth/menu/${mid}/contents',
          consoleMenuContent: '/auth/menu/${mid}/content/${cid}',
          consoleContent: '/auth/content/${id}',
          consoleChangePwd: '/auth/changePwd',

          consoleAdminUser: '/auth/adminUser',
          consoleAdminUserRoleList: '/auth/adminUser/roles',
          consoleFile: '/auth/file',

          userLoginLink: '/login',
          userLogoutLink: '/logout',
          userLogin: '/console/signIn',
          userInfo: '/user/info',
        }
        let url = (process.env.VUE_APP_API_URL) ? `${process.env.VUE_APP_API_URL}${resource[r]}` : resource[r]
        return url
    }
}