<template>
    <div class="pb-10">
        <div>
            <v-btn
                class="mr-4"
                min-width="0"
                outlined
                text
                small
                @click="$router.go(-1)"
                aria-label="回上一頁"
            >
                <v-icon>mdi-arrow-left</v-icon>回上一頁
            </v-btn>
        </div>
         <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">資料確認</h2>
        </div>
        <div class="main-content py-3 px-10">
            <form ref="form">

                <div>
                    <label>暱稱</label>
                    <v-text-field
                        ref="userName"
                        v-model="userName"
                        label="輸入暱稱"
                        :error-messages="requiredErrors('userName')"
                        outlined
                        solo
                    ></v-text-field>
                </div>
                <div>
                    <label>座號</label>
                    <v-text-field
                        ref="seatNo"
                        v-model="seatNo"
                        label="輸入座號"
                        :error-messages="requiredErrors('seatNo')"
                        outlined
                        solo
                    ></v-text-field>
                </div>
                <div>
                    <label>性別</label>
                    <v-radio-group
                        ref="gender"
                        :error-messages="requiredErrors('gender')"
                        v-model.trim="$v.gender.$model"
                        @input="$v.gender.$touch()"
                        @blur="$v.gender.$touch()"
                        row
                    >
                        <v-radio
                            label="男"
                            value="MALE"
                            color="#82bf5a"
                        ></v-radio>
                        <v-radio
                            label="女"
                            value="FEMALE"
                            color="#82bf5a"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <!-- <div>
                    <label>出生年月日</label>
                    <v-menu
                        v-model="birthDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                ref="birthDate"
                                v-model.trim="$v.birthDate.$model"
                                :error-messages="
                                    requiredErrors('birthDate')
                                "
                                @input="$v.birthDate.$touch()"
                                @blur="$v.birthDate.$touch()"
                                label="年-月-日"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="birthDate"
                            @input="birthDateMenu = false"
                            type="date"
                            locale="zhHant"
                            max="2016-09-01"
                            min="2006-09-01"
                        ></v-date-picker>
                    </v-menu>
                </div> -->
                
                <div>
                    <label>學校</label>
                    <v-autocomplete
                        ref="school"
                        v-model.trim="$v.school.$model"
                        :error-messages="requiredErrors('school')"
                        @input="$v.school.$touch()"
                        @blur="$v.school.$touch()"
                        :items="schools"
                        label="選擇學校"
                        item-text="name"
                        item-value="id"
                        outlined
                        solo
                    ></v-autocomplete>
                    <v-text-field
                        v-show="school == -1"
                        label="請輸入縣市和學校名稱"
                        hint="例如：高雄市三民區十全國民小學"
                        v-model.trim="$v.schoolName.$model" 
                        :error-messages="requiredErrors('schoolName')"
                        outlined
                        solo
                    ></v-text-field>
                </div>

                <div v-if="schoolName != ''">
                    <label>年級</label>
                    <v-select
                        ref="grade"
                        v-model.trim="$v.grade.$model"
                        :error-messages="requiredErrors('grade')"
                        @input="$v.grade.$touch()"
                        @blur="$v.grade.$touch()"
                        :items="grades"
                        label="選擇年級"
                        item-text="name"
                        item-value="id"
                        outlined
                        solo
                        @change="readSchoolClass"
                    ></v-select>
                </div>

                <div v-if="schoolName != '' && schoolClasses.length > 0">
                    <label>班級</label>
                    <v-select
                        ref="schoolClass"
                        v-model.trim="$v.schoolClass.$model"
                        :error-messages="requiredErrors('schoolClass')"
                        @input="$v.schoolClass.$touch()"
                        @blur="$v.schoolClass.$touch()"
                        :items="schoolClasses"
                        label="選擇班級"
                        item-text="name"
                        item-value="id"
                        outlined
                        solo
                    ></v-select>
                    <v-text-field
                        v-show="schoolClass == -1"
                        label="輸入班級名稱"
                        v-model.trim="$v.schoolClassName.$model" 
                        :error-messages="requiredErrors('schoolClassName')"
                        outlined
                        solo
                    ></v-text-field>
                </div>
                <div>
                    <v-checkbox
                        ref="memberPrivacy"
                        v-model.trim="$v.memberPrivacy.$model"
                        :error-messages="requiredErrors('memberPrivacy')"
                        @input="$v.memberPrivacy.$touch()"
                        @blur="$v.memberPrivacy.$touch()"
                    >
                        <template v-slot:label>
                            <div>
                                我同意
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <a
                                            target="_blank"
                                            @click="termDialog = true"
                                            v-on="on"
                                        >
                                            會員條款與隱私權
                                        </a>
                                    </template>
                                    會員條款與隱私權
                                </v-tooltip>
                            </div>
                        </template>
                    </v-checkbox>
                </div>
                <div>
                    <v-btn
                        class="w-100"
                        color="#82bf5a"
                        @click="goToQuiz()"
                        dark
                         title="送出" value="送出" aria-label="送出"
                    >
                        送出
                    </v-btn>
                </div>
            </form>
        </div>
        
        <v-dialog v-model="termDialog" persistent max-width="350">
            <v-card class="pb-2">
                <v-card-text class="body-1 pt-10">
                    為保護您的權益，請詳細閱讀以下隱私權政策。當您開始進行測驗，即代表您同意本服務的隱私權政策。<br />
                    1. 資料蒐集與運用<br />
                    當您使用本服務進行檢測時，本會將要求您（使用者）提供性別、出生年月日、工作情況、婚姻狀況、居住狀況、最高學歷、居住縣市等、測驗填答內容與結果，這些資料將可能作為本會非營利性質的學術研究或教育推廣使用。<br />
                    2. 個人資料安全<br />
                    a.
                    本會依相關法律規定，採取適當的安全措施，以保存您所提供的個人資料，並且僅限於因業務需求相關的本會人員，始有權限接觸到您的個人資料。<br />
                    b.
                    所有資料僅供本會為了提供服務與非營利學術研究或推廣使用，除非事先經過您的同意或依相關法令規定，不會將個人資料提供給第三方或轉作其他目的使用。
                </v-card-text>

                <v-card-actions class="mt-5">
                    <v-btn
                        outlined
                        @click="termDialog = false"
                        block
                    >
                        確定
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { userServices } from "@/services/user.js";
import { schoolServices } from "@/services/school.js";
import { quizServices } from "@/services/quiz.js";

export default {
    name: "PersonalData",
    components: {},
    data() {
        return {
            quizId: -1,
            city: "",
            userName: "",
            edu: "",
            birthDateMenu: false,
            birthDate: "",
            gender: "",
            memberPrivacy: false,
            grades: [],
            grade: "",
            schools: [],
            school: "",
            schoolName: "",
            schoolClasses: [],
            schoolClass: "",
            schoolClassName: "",
            seatNo: "",
            nowDate: new Date('2016-09-01').toISOString().slice(0,10),
            termDialog: false,
            isShowPlaceField: false,
        };
    },
    async created() {
        
        let id = this.$route.params.id;
        const res = await quizServices.getQuizInstructions(id);
        const quiz = res.data;
        if (!quiz.id) {
            this.$router.push("/scale")
        }
        if (quiz.id != sessionStorage.quiz_id) {
            this.$router.push("/scale/:id/instruction".replace(':id', quiz.id))
        }
        this.quizId = quiz.id;

        this.$store.commit("setQuizTitle", quiz.title);
        //this.$root.$emit('scaleTitle', quiz.title);

        sessionStorage.quiz_id = quiz.id;

        //const gradeRes = await schoolServices.getGrade();
        const gradeList = ['一', '二', '三', '四', '五', '六'];
        gradeList.forEach(item => {
            this.grades.push({
                id: item,
                name: `${item}`
            })
        });

        await this.readSchool();
        const saveUserData = localStorage.getItem('userData');
        if (saveUserData != null) {
            const userData =  JSON.parse(saveUserData)
            this.birthDate = userData.birthDate
            this.gender = userData.gender
            this.hasSpirit = userData.policy.hasSpirit
        }
        
    },
    validations: {
        // city: { required },
        // edu: { required },
        // birthDate: { required },
        gender: { required },
        grade: { required},
        userName: { required},
        school: {required},
        schoolName: {required},
        schoolClass: {required},
        schoolClassName: {required},
        seatNo: {required},
        // liveStatus: { required },
        // maritalStatus: { required },
        memberPrivacy: { required, sameAs: (val) => val === true },
        // placeName: { required },
    },
    watch: {
        school(val) {
            if (val != null) {
                if (val != '-1') {
                    this.schoolName = val;
                } else {
                    this.schoolName = '';
                }
            }
        },
        schoolClass(val) {
            if (val != null) {
                if (val != '-1') {
                    this.schoolClassName = val;
                } else {
                    this.schoolClassName = '';
                }
            }
        },
    },
    methods: {
        requiredErrors(input) {
            const errors = [];
            
            if (!this.$v[input].$dirty) return errors;
            if (!this.$v[input].required) {
                switch (input) {
                    // case "edu":
                    //     errors.push("請選擇最高學歷");
                    //     break;
                    // case "city":
                    //     errors.push("請選擇居住地");
                    //     break;
                    case "userName":
                        errors.push("請填寫姓名");
                        break;
                    case "gender":
                        errors.push("請選擇性別");
                        break;
                    // case "birthDate":
                    //     errors.push("請填寫生日");
                    //     break;

                    case "grade":
                        errors.push("請選擇年級");
                        break;

                    case "school":
                        errors.push("請選擇學校");
                        break;
                    case "schoolName":
                        errors.push("請輸入縣市和學校名稱");
                        break;
                    case "schoolClass":
                        errors.push("請選擇班級");
                        break;
                    case "schoolClassName":
                        errors.push("請輸入班級名稱");
                        break;
                    case "seatNo":
                        errors.push("請輸入座號");
                        break;
                    // case "liveStatus":
                    //     errors.push("請選擇居住狀況");
                    //     break;
                    // case "maritalStatus":
                    //     errors.push("請選擇婚姻狀況");
                    //     break;
                    // case "placeName":
                    //     errors.push("請輸入居住地");
                    //     break;
                }
            } else {
                if (input === "memberPrivacy" && !this.$v[input].sameAs) {
                    errors.push("請同意會員條款與隱私權");
                }
            }

            return errors;
        },
        async readSchool() {
            const schoolRes = await schoolServices.getSchool({
                
            })
            this.school = '';
            this.schoolClass = '';
            this.schools.length = 0;
            this.schoolClasses.length = 0;
            
            schoolRes.data.forEach(item => {
                this.schools.push({
                    id: item.school_name,
                    name: item.school_name
                })
            });
            this.schools.push({
                id: -1,
                name: '其他'
            })
        },

        async readSchoolClass() {
            // console.log('this.school:', this.school)
            // const res = await schoolServices.getSchoolClass({
            //     school_id: this.school,
            //     grade: this.grade
            // })
            this.schoolClasses.length = 0;
            this.schoolClass = '';
            const classNoList = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
            classNoList.forEach(item => {
                this.schoolClasses.push({
                    id: item,
                    name: `${item}`
                })
            });
            this.schoolClasses.push({
                id: -1,
                name: '其他'
            })
        },
        async goToQuiz() {
            this.$v.$reset();
            this.$v.$touch();
            if (!this.$v.$invalid) {
                let id = await this.saveUserData()
                sessionStorage.user_id = id;
                this.$router.push("/scale/:id/quest".replace(':id', this.$route.params.id))
            } 
        },
        async saveUserData() {
            let data = {
                birthDate: this.birthDate,
                gender: this.gender,
                name: this.userName,
                grade: this.grade,
                school: this.schoolName,
                school_class: this.schoolClassName,
                applyMemberPrivacy: this.memberPrivacy,
                seat_no: this.seatNo,
            };
            localStorage.setItem('userData', JSON.stringify(data))
            let user = await userServices.saveUserData(data);
            return user.data;
        },
    },
};
</script>

<style scoped>
.title {
    width: 300px;
    padding-top: 30px;
    margin-top: 30px;
    padding-bottom: 40px;
    margin-bottom: 40px;
}

.title span {
    padding-bottom: 10px;
    border-bottom: 2px solid #20780c;
    line-height: 48px;
}

.v-input--selection-controls {
    margin-top: 2px;
    padding-top: 4px;
    margin-bottom: 2px;
    padding-bottom: 4px;
}

.v-text-field {
    padding-top: 12px;
    margin-top: 2px;
    margin-bottom: 2px;
    padding-bottom: 4px;
}

.v-input__slot {
    margin-top: 6px;
    margin-bottom: 6px;
}
.main-content {
    max-width: 650px;
}
</style>