import servicesUtils from '../utils/service'

export const userLoginServices = {
    async oauthLogin() {
        try {
            const url = `${servicesUtils.resourceUrl('userLoginLink')}?redirect_uri=${process.env.VUE_APP_LOGIN_REDIRECT_URL}`;
            console.log('url:', url);
            const response = await servicesUtils.get(url);
            const oauthLink = response.link;
            location.href = oauthLink;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async oauthLogout() {
        try {
            const url = `${servicesUtils.resourceUrl('userLogoutLink')}`;
            const response = await servicesUtils.get(url);
            const oauthLink = response.link;
            return oauthLink;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async login() {
        try {
            const url = `${servicesUtils.resourceUrl('userLogin')}`
            const data = await servicesUtils.post(url);
            return data;
        } catch (err) {
            console.log(`failed to login err: ${err}`);
            throw err
        }
    },
    async logout() {
        const url = `${servicesUtils.resourceUrl('userLogout')}`
        const data = await servicesUtils.get(url)
        return data
    },
    async getUserInfo(data) {
        const url = `${servicesUtils.resourceUrl('userInfo')}`;
        const response = await servicesUtils.post(url, data);
        return response.data;
    },
}