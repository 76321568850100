<template>
    <div ref="printMe" class="px-5 py-3">
        <h2 class="print-title mb-7">兒童圖解強項量表</h2>
        <div>
            <v-btn class="mr-4 back-menu-btn" color="secondary" min-width="0" outlined text href="/scale" title="回量表選單"
                value="回量表選單" aria-label="回量表選單">
                <v-icon>mdi-arrow-left</v-icon>回量表選單
            </v-btn>
        </div>
        <div class="row no-gutters justify-center mt-4">
            <h2 class="page-title col-auto">評量結果</h2>
        </div>
        <div class="main-content row no-gutters mt-4 pa-md-7">
            <div>親愛的{{ userName }}：</div>
            <div>
                {{ intro }}
            </div>
            <v-row class="mt-5">
                <v-col v-for="(item) in resultList" :key="item.key" cols="12" sm="6" md="4" class="card-item">
                    <v-card elevation="0" width="100%" height="100%" class="result-card py-3 px-4"
                        :class="item.hasCrown ? 'has-crown' : ''">
                        <v-row no-gutters style="height: 100%" align-content="start">
                            <v-col cols="12" align-self="center" class="pa-2 mb-3">
                                <v-img class="mx-auto" style="width: 100px" :src="item.displayIcon"></v-img>
                            </v-col>
                            <v-col cols="12">
                                <v-list-item-title>
                                    <v-chip :color="item.hasCrown ? 'red lighten-2' : 'grey'" text-color="white">{{
                                        item.title }}</v-chip>
                                    <v-icon v-if="item.hasCrown" aria-hidden="false" style="
                                            position: absolute;
                                            left: -15px;
                                            top: -20px;
                                            color: #ffb007;
                                            font-size: 2.5rem;
                                            background: #fff;
                                            transform: rotateZ(-20deg);
                                            border-radius: 50%;">
                                        mdi-crown-circle
                                    </v-icon>
                                    <span :class="item.hasCrown ? 'red--text text--lighten-2' : ''">
                                        {{ item.score }}
                                    </span>
                                    <span>
                                        / 15
                                    </span>
                                </v-list-item-title>

                                <ul class="mt-2">
                                    <li v-for="(comment, cind) in item.comment" :key="cind">
                                        {{ comment }}
                                    </li>
                                </ul>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <div class="w-100 text-right mt-10 print-powerby">高雄醫學大學 X 漫話科技 提供</div>
            <v-btn class="print-btn white--text mx-auto mt-10" large elevation="0" color="red accent-1"
                @click="downloadResult">下載結果</v-btn>

        </div>
        <v-overlay :value="overlay" opacity="1">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
    </div>
</template>


<script>
import { quizServices } from "@/services/quiz.js";
import html2canvas from 'html2canvas';
import Canvas2Image from 'canvas2image-2';
import FileSaver from 'file-saver';
export default {
    name: "QuizResult",
    components: {},
    data() {
        return {
            totalScoreTitle: '',
            totalScoreDescript: '',
            loaded: false,
            userName: '使用者',
            intro: '',
            resultList: [],
            overlay: false,
        }
    },
    async mounted() {

        this.loaded = false;
        let uuid = this.$route.params.uuid;
        const returnData = await quizServices.getQuizResult(uuid);

        let quizData = returnData.data;
        if (quizData.user_name)
            this.userName = quizData.user_name;
        this.intro = quizData.intro;
        let results = quizData.result;

        this.$store.commit("setQuizTitle", quizData.title);
        //this.$root.$emit('scaleTitle', quizData.title);
        sessionStorage.removeItem('quiz_id');
        sessionStorage.removeItem('user_id');

        const scoreList = [];
        Object.keys(results).forEach(function (key) {
            let item = results[key];
            item['key'] = key;
            scoreList.push(item);
        });

        scoreList.sort((a, b) => b.score - a.score);

        let thirdScore = 0;
        for (let scoreInd = 0; scoreInd < scoreList.length; scoreInd++) {
            let scoreItem = scoreList[scoreInd];

            if (scoreInd < 3) {
                scoreItem['displayIcon'] = require(`@/assets/img/result/${scoreItem.key}_red.png`);
                scoreItem['hasCrown'] = true;
                if (scoreInd == 2) {
                    thirdScore = scoreItem.score;
                }
            }

            if (thirdScore == scoreItem.score) {
                scoreItem['displayIcon'] = require(`@/assets/img/result/${scoreItem.key}_red.png`);
                scoreItem['hasCrown'] = true;
            }

            if (!scoreItem['hasCrown']) {
                scoreItem['displayIcon'] = require(`@/assets/img/result/${scoreItem.key}_yellow.png`);
            }
        }
        this.resultList = scoreList;
    },
    methods: {
        async downloadResult() {
            let self = this;
            this.overlay = true;
            const $shareContent = this.$refs.printMe;
            $shareContent.classList.add("print");
            var width = $shareContent.offsetWidth; //獲取dom 寬度
            var height = $shareContent.offsetHeight; //獲取dom 高度
            var canvas = document.createElement("canvas"); //建立一個canvas節點
            var scale = 1.5; //定義任意放大倍數 支援小數
            canvas.width = width * scale; //定義canvas 寬度 * 縮放
            canvas.height = height * scale; //定義canvas高度 *縮放
            canvas.getContext("2d").scale(scale, scale); //獲取context,設定scale 
            var opts = {
                scale: 1, // 新增的scale 引數
                canvas: canvas, //自定義 canvas
                // logging: true, //日誌開關，便於檢視html2canvas的內部執行流程
                width: width, //dom 原始寬度
                height: height,
                useCORS: true // 【重要】開啟跨域配置
            };
            html2canvas($shareContent, opts).then(function (canvas) {
                var context = canvas.getContext('2d');
                // 【重要】關閉抗鋸齒
                context.mozImageSmoothingEnabled = false;
                context.webkitImageSmoothingEnabled = false;
                context.msImageSmoothingEnabled = false;
                context.imageSmoothingEnabled = false;

                // const a = document.createElement('a');
                // a.download = `${self.userName}的兒童版強項量表圖像.jpg`;

                let img = Canvas2Image.convertToImage(canvas, canvas.width * scale, canvas.height * scale, 'jpeg');
                const byteCharacters = window.atob(img.getAttribute('src').split(',')[1]);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers[i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const blob = new Blob([byteArray], { type: 'image/jpg' });
                FileSaver.saveAs(blob, `${self.userName}的兒童版強項量表圖像.jpg`);

                // a.href = img.getAttribute('src');
                // console.log(a.href)
                // a.style.display = 'none';
                // document.body.appendChild(a);
                // a.click();
                // document.body.removeChild(a);

                $shareContent.classList.remove("print");
                setTimeout(() => {
                    self.overlay = false;
                }, "1000");

            });
        }
    },
};
</script>

<style scoped>
.print-title::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    content: '';
    background-image: url(~@/assets/img/main_logo.png);
    background-position: center center;
    background-size: contain;
    max-height: 65px;
    max-width: 150px;
    height: 100%;
    width: 100%;
    color: transparent;
    display: block;
}

.print-title {
    display: none;
    position: relative;
    background-color: #f08c7f;
    color: #fff;
    padding: 30px 0;
    text-align: center;
}

.print-powerby {
    display: none;
}

.print .print-powerby {
    display: block;
}

.print {
    width: 1024px !important;
    padding: 0 !important;
}

.print .print-title {
    display: block;
}

.print .main-content {
    padding: 10px 40px 40px !important;
}

.print .back-menu-btn,
.print .print-btn {
    display: none;
}

.print-btn {
    font-size: 1.2rem !important;
}

.print .card-item {
    flex: 0 0 33.3333333333%;
}

.print .card-item-content {
    flex: 0 0 100%;
}

.total-score {
    position: relative;
    text-align: center;
}

.total-score>img {
    max-width: 200px !important;
    width: 100%;
}

.total-score>span {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -16%);
    left: 50%;
    font-size: 4rem;
    color: #fff;
}

.result-card.has-crown {
    border: 1px solid #f08c7f;
    border-radius: 15px;
}

.result-card:not(.has-crown) {
    border-width: 0;
    background-color: transparent;
}

.v-chip.v-size--default {
    font-size: 1.1rem !important;
}

.v-list-item__title {
    font-size: 1.1rem !important;
}
</style>